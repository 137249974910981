import { gql } from '@apollo/client'

export const RESOLVE_SHORTENED_URL = gql`
  query ResolveShortenedUrl($shortUrlKey: String!) {
    resolveData(shortUrlKey: $shortUrlKey) {
      ... on UrlShortenerResolvedResponse {
        shortUrlKey
        originalUrl
        resolved
        expiry
      }
      ... on UrlShortenerResponseError {
        errorCode
        message
        statusCode
      }
    }
  }
`
