import * as React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import GraphQLClient from '../api/graphql/graphql_client'
import { CustomErrorContainer, HomeV2, HomeV1 } from '@local/home'

export const routes = [
  {
    path: '/r/check',
    element: <HomeV2 />
  },
  {
    path: '/r/:shortUrlKey',
    element: <HomeV1 />
  },
  {
    path: '*',
    element: <CustomErrorContainer />
  }
]

export function App() {
  const router = createBrowserRouter(routes)

  return (
    <ApolloProvider client={GraphQLClient}>
      <RouterProvider router={router} />
    </ApolloProvider>
  )
}
