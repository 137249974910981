import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { getG2FrontendUrl } from '../uri/functions'

const g2FrontendUrl = getG2FrontendUrl()
const uri = `${g2FrontendUrl}/url-shortener/v1/graphql`

const httpLink = new HttpLink({ uri, credentials: 'same-origin' })

const GraphQLClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
})

export default GraphQLClient
