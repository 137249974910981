import {
  ToastEnvironment,
  getCurrentEnvironment
} from '@toasttab/current-environment'

import {
  G2_FRONTEND_URL_DEV,
  G2_FRONTEND_URL_PREPROD,
  G2_FRONTEND_URL_PROD,
  G2_FRONTEND_URL_SANDBOX
} from './constants'

export const getG2FrontendUrl = () => {
  const env = getCurrentEnvironment()

  if (env === ToastEnvironment.DEV) {
    return G2_FRONTEND_URL_DEV
  } else if (env === ToastEnvironment.SANDBOX) {
    return G2_FRONTEND_URL_SANDBOX
  } else if (env === ToastEnvironment.PREPROD) {
    return G2_FRONTEND_URL_PREPROD
  } else {
    return G2_FRONTEND_URL_PROD
  }
}
