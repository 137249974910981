import * as React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { RESOLVE_SHORTENED_URL } from '../../../../src/api/graphql/graphql'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useHeap } from '@toasttab/use-heap'
import {
  ErrorContainer,
  ErrorHeading,
  ErrorSubheading
} from '@toasttab/buffet-pui-error-pages'
import {
  PageMissing404DesktopIllustration,
  PageMissing404MobileIllustration
} from '@toasttab/buffet-pui-illustrations'

export interface HomeProps {
  testId?: string | number
}

function isToastTabUrl(url: string) {
  const regex = /^https?:\/\/([a-zA-Z0-9.-]+\.)?toasttab.com(\/.*)?$/
  return regex.test(url)
}

export const HomeV2 = () => {
  const [searchParams] = useSearchParams()
  const shortUrlKey = searchParams.get('hash')
  return Boolean(shortUrlKey) ? (
    <Home shortUrlKey={shortUrlKey} />
  ) : (
    <CustomErrorContainer />
  )
}

export const HomeV1 = () => {
  const { shortUrlKey } = useParams()
  return Boolean(shortUrlKey) ? (
    <Home shortUrlKey={shortUrlKey} />
  ) : (
    <CustomErrorContainer />
  )
}

const Home = (props: { shortUrlKey: string | null | undefined }) => {
  const { track } = useHeap()
  const [countdown, setCountdown] = React.useState(10)
  const [resolvedUrl, setResolvedURL] = React.useState<boolean | undefined>(
    undefined
  )

  React.useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown: number) => prevCountdown - 1)
    }, 1000)

    if (countdown === 0) {
      clearInterval(countdownInterval)
    }

    return () => clearInterval(countdownInterval)
  }, [countdown])

  const { data: queryData } = useQuery(RESOLVE_SHORTENED_URL, {
    variables: { shortUrlKey: props.shortUrlKey }
  })
  const originalUrl: string = queryData?.resolveData.originalUrl

  React.useEffect(() => {
    setResolvedURL(queryData?.resolveData.resolved)
  }, [queryData])

  React.useEffect(() => {
    track('URL_SHORTENER_PUBLIC_LOADED')
  }, [])

  if (resolvedUrl !== undefined) {
    if (!resolvedUrl) {
      // 404
      return <CustomErrorContainer />
    } else {
      const url = new URL(originalUrl)
      if (isToastTabUrl(originalUrl)) {
        if (countdown !== 0) setCountdown(0)
        window.location.replace(url)
        return (
          <div className='flex items-center justify-center w-full h-screen'>
            <MerryGoRound size='xl' />
          </div>
        )
      } else {
        if (countdown > 3) {
          setCountdown(3)
        } else if (countdown === 0) {
          window.location.replace(url)
        }

        return (
          <div
            data-testid='speedbump'
            className='flex flex-col justify-center text-center items-center w-screen h-[90vh] p-4'
          >
            {/* <div className=' flex flex-col items-center justify-center w-vw h-screen p-2 '></div> */}
            <img
              className='w-12 h-12 sm:w-16 sm:h-16'
              src='https://cdn.toasttab.com/static/19af6b673996d663903a4cf872baa27dbc0e8f9d/images/toast_icon.png'
              alt='toast logo'
            />
            <p className='type-headline-4 font-medium mt-4 mb-6 sm:type-headline-2'>
              Leaving Toast in {countdown} seconds...
            </p>
            <p className=' font-normal type-default sm:type-headline-5'>
              or click the link below
            </p>
            <p className='text-link mt-2 break-all font-medium sm:type-headline-5 sm:w-[640px]'>
              <a
                data-testid='redirected-link-tag'
                className='break-all'
                href={originalUrl}
              >
                {originalUrl.length > 120
                  ? `${originalUrl.slice(0, 60)}...${originalUrl.slice(60)}`
                  : `${originalUrl.slice(0, 60)}`}
              </a>
            </p>
          </div>
        )
      }
    }
  }

  return (
    <div className='flex items-center justify-center w-full h-screen'>
      <MerryGoRound size='xl' />
    </div>
  )
}

export const CustomErrorContainer = () => {
  return (
    <div
      data-testid='error-component'
      className='flex justify-center items-center h-screen'
    >
      <ErrorContainer>
        <div className='flex justify-center items-center'>
          <div
            className='hidden md:block visible mx-auto'
            style={{
              maxWidth: 600
            }}
          >
            <PageMissing404DesktopIllustration />
          </div>
          <div className='block md:hidden visible mx-auto'>
            <PageMissing404MobileIllustration />
          </div>
        </div>
        <div className='justify-center flex-col'>
          <ErrorHeading>Oops! Page not found</ErrorHeading>
          <ErrorSubheading>
            Seems Like there was some problem processing your request
          </ErrorSubheading>
        </div>
      </ErrorContainer>
    </div>
  )
}
